// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.vide {
  position: relative;
  overflow: hidden;
  height: 100vh; /* Full viewport height */
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1; /* Keep it behind other elements */
  transform: translate(-50%, -50%);
}

.vide_content {
  position: relative;
  z-index: 1; /* Bring content in front of the video */
  color: white; /* Change text color for visibility */
  text-align: center; /* Center align text */
}

.NumberOfExoplanetCardContainer{
  display: flex;
  ;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;;;AAIA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,aAAa,EAAE,yBAAyB;AAC1C;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,WAAW,EAAE,kCAAkC;EAC/C,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,UAAU,EAAE,wCAAwC;EACpD,YAAY,EAAE,qCAAqC;EACnD,kBAAkB,EAAE,sBAAsB;AAC5C;;AAEA;EACE,aAAa;;AAEf","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n\n.vide {\n  position: relative;\n  overflow: hidden;\n  height: 100vh; /* Full viewport height */\n}\n\n.background-video {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  min-width: 100%;\n  min-height: 100%;\n  width: auto;\n  height: auto;\n  z-index: -1; /* Keep it behind other elements */\n  transform: translate(-50%, -50%);\n}\n\n.vide_content {\n  position: relative;\n  z-index: 1; /* Bring content in front of the video */\n  color: white; /* Change text color for visibility */\n  text-align: center; /* Center align text */\n}\n\n.NumberOfExoplanetCardContainer{\n  display: flex;\n  ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
